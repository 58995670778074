.big_content_contact{
  color: white;
}

html{
  margin: 0;
  padding: 0;
}

.main_item{
  list-style-type: none;
  font-size: 20px;
  font-weight: 400;
}

.name{
  font-family: 'Italiana', serif;
}

ul{
  padding-left: 0 !important;
  margin: 0;
  list-style-type: none;
}

ul li{
  font-size: 18px;
  font-weight: lighter;
}

.card_back{
  background-color: transparent !important;
  border: 0;
  display: flex;
  padding: 0 20px;
  align-content: center;
}

.card_back:hover{
  border: 0 !important;
  cursor: crosshair;
}
.text-capitalize{
  font-size: 16px;
}

.context{
  margin:  0 auto;
}

@media only screen and (max-width: 768px){
  .context{
    padding: 0;
  }
  .card_back{
    background-color: transparent !important;
    border: 0;
    display: flex;
  }
}
