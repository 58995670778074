.cardd_feet {
    max-width: 73px;
    object-fit: cover;
    border-radius: 20px;
    opacity: .5;
    cursor: pointer;
    padding: 0 3px;
    margin: 0 10px;
}

a {
    color: #fff;
}

.carddd {
    opacity: 1;
}


.maincardd {
    width: 300px;
    height: 300px;
    border-radius: 50px;
    margin: 0 auto !important;
    display: flex !important;
}

.maincardd img {
    width: 400px;
    height: 260px;
    object-fit: cover;
}

.btn {
    width: 100% !important;
    margin: 0 auto !important;
}

@media (max-width: 786px) {
    .maincardd img {
        width: 400px;
        height: 200px;
    }

    .quti {
        display: block;
    }
}


@media (max-width: 576px) {
    .maincardd {
        width: 300px;
        height: 250px !important;
    }

    .quti {
        display: block;
    }
}