.mycon{
    display: flex !important;
    align-items: center;
    margin-top: 150px !important;
    background-color: #0c0061;

}
.cardmy{
    height: 150px;
    display: flex !important;
    align-items: center;
    color: #fff;
}

.cardmy i{
    font-size: 25px;
    padding: 15px 20px;
    border-radius: 50%;
    background-color: #0c0032;
    margin-right: 10px;
}
