.big_content{
  color: white;
}


.description1 {
  width: 100% !important ;
  font-size: 18px;
  font-weight: lighter;
}


@media only screen and (max-width: 768px) {
  .products{
    font-size: 40px;
  }

  .description1{
    font-size: 13px;
    line-height: 20px;
  }

  .content_top{
    flex-direction: column-reverse;
  }

  .big_content{
    padding: 0 10px;
  }
}