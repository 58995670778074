
.__react_modal_image__medium_img{
    max-width: 500px ; 
    height: 300px;
    background-color: white !important;
    object-fit: cover;
    padding: 1rem;
}

.img_card {
    padding: 20px 5px;
    /* width: 150px; */
    height: 200px;
    margin: 1rem;
    text-align: center;
    border-radius: 10px;
}
.img_card img{
    width: 120px;
    height: 100px;
}
.img_card:last-child img{
    object-fit: contain;
}

@media (max-width: 576px) {
    .__react_modal_image__medium_img{
        max-width: 80vw !important ; 
        height: 50vh !important;
        background-color: white !important;
        object-fit: contain;
        /* padding: 1rem; */
    }
}
