.big_content{
  color: white;
}

.pattern_black{
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 25, 0.5);
}

.texts{
  height: 100%;
  justify-content: center;
}

.texts p:first-child{
  font-size: 60px;
}

.texts p:last-child{
  font-size: 20px;
}
.span{
  font-family: serif;
}
.products{
  font-size: 60px;
  text-align: center;
}

.col_back__img{
  position: relative;
  left: -20px;
}

.col_back__img .img{
  width: 65%;
  height: max-content;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 15px;
  /* height: 300px; */
}

.col_back__img img{
  width: 62%;
  height: max-content;
  position: relative;
}

.text_button{
  position: relative;
  z-index: 2;
  border: 1px solid white;
  width: max-content;
  background-color: white;
  color: #0C0061;
  font-family: 'Italiana', serif;
  padding: 10px 20px;
  min-width: 200px;
  left: calc(100% - 54%);
}

.information_div{
  width: 100%;
  height: 100%;
  position: relative;
}

.information_div::-webkit-scrollbar{
  display: none;
}

@media only screen and (max-width: 992px) {
  .text_button{
    font-size: 14px;
  }

  .information_div{
    overflow-x: scroll;
    position: relative;
    right: 30px;
  }

  

}

@media only screen and (max-width: 760px) {
  .texts p:first-child{
    font-size: 40px;
  }

  .col_back__img .img{
    width: 60%;
    height: 300px;
    right: 50px;
  }
  
  .col_back__img img{
    width: 60%;
  }

  .col_back__img{
    left: -10px;
  }

  .text_button{
    font-size: 14px;
  }
}
