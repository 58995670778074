.MYcard {
  position: relative;
  width: 100%;
  border-radius: 51px;
  text-align: center;

}

.cardd{
  width: 30%;
}

.MYcard:hover{
  border-radius: 51px;
  box-shadow: rgba(255, 255, 255, 0.50) 0px 0px 10px;
}

.mainCard {
  margin: 0 0 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
}

.MYcard img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  border-radius: 51px;
}


.MYcard button {
  width: 174px;
  height: 38px;
  border: 1px solid #fff;
  border-radius: 50px;
  background-color: #0c0061;
  color: #fff;
  position: absolute;
  bottom: 15%;
  left: 25%;
  z-index: 1;
}

.botsec {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 5px 0;
  background-color: #0c0061;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  color: #fff;
}


.botsec p {
  margin-top: 25px;
  font-weight: bold;

}


@media (min-width: 1440px) {
  .MYcard {
    position: relative;
    border-radius: 51px;
    text-align: center;

  }

  .MYcard img {
    width: 100%;
    height: 300px;
  }

  .MYcard button {
    width: 174px;
    height: 38px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #0c0061;
    color: #fff;
    position: absolute;
    bottom: 14%;
    left: 27%;
    z-index: 1;
  }
}

@media (max-width: 1200px) {

  .botsec {
    height: 60px;
    padding: 0 10px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  .MYcard button {
    width: 160px;
    height: 35px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #0c0061;
    color: #fff;
    position: absolute;
    bottom: 22%;
    left: 20%;
    z-index: 1;
  }
  .botsec p{
    font-size: 14px;
  }

}

@media (max-width: 992px) {
  .MYcard {
    position: relative;
    border-radius: 51px;
    text-align: center;

  }

  .cardd {
    width: 40% !important;
  }

  .MYcard img {
    width: 100%;
  }

  .MYcard button {
    width: 160px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #0c0061;
    color: #fff;
    position: absolute;
    bottom: 18%;
    left: 22%;
    z-index: 1;
  }
  .botsec p{
    font-size: 12px;
  }
}

@media (max-width: 769px) {
  .mainCard {
    height: auto;
    margin-bottom: 50px;
    margin: 0 auto;
    padding: 20px 0;
  }
  .botsec p{
    font-size: 12px;
  }

  .MYcard {
    position: relative;
    border-radius: 51px;
    text-align: center;
  }


  .MYcard img {
    width: 100%;
  }

  .MYcard button {
    width: 150px;
    height: 38px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #0c0061;
    color: #fff;
    position: absolute;
    bottom: 15%;
    left: 25%;
    z-index: 1;
  }
}
@media (max-width: 575px) {
  .mainCard {
    margin: 0 auto !important;
  }

  .cardd {
    width: 100% !important;
  }

  .MYcard {
    position: relative;
    border-radius: 51px;
    text-align: center;
    margin: 30px 0;
  }

  .MYcard img {
    width: 100%;
  }
  .MYcard button {
    width: 170px;
    height: 38px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #0c0061;
    color: #fff;
    position: absolute;
    bottom: 10% ;
    left: 35%;
    z-index: 1;
  }

  .botsec {
    height: 60px;
    padding: 0;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }
}

@media (max-width: 446px) {
  .mainCard {
    margin: 0 auto !important;
  }

  .cardd {
    width: 100% !important;
  }

  .MYcard {
    position: relative;
    border-radius: 51px;
    text-align: center;
    margin: 30px 0;
  }

  .MYcard img {
    width: 100%;
  }

  .MYcard button {
    width: 160px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #0c0061;
    color: #fff;
    position: absolute;
    bottom: 16%;
    left: 27%;
    z-index: 1;
  }

  .botsec {
    height: 60px;
    padding: 0;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }
  .botsec p{
    font-size: 18px;
  }

}