.big_content__navbar {
  position: absolute;
  width: 100vw;
  position: fixed !important;
  z-index: 1010;
}

.navbar {
  background-color: #0C0061;
  font-size: 20px;
  width: 100%;
  z-index: 1003;
}

.navbar-dark {
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
}

.nav_top__section {
  background-color: #0C0032;
}

.child_section {
  display: flex;
  position: relative;
  top: 0;
  width: 100vw;
  justify-content: space-between;
  color: white;
  align-items: center;
  z-index: 1006;
  padding: 4px 0;
}

.phone_number {
  font-size: 15px;
}

.phone_number a:hover {
  color: white;
}

.flags img {
  height: 20px !important;
  width: 20px !important;
  object-fit: cover;
  margin-right: 5px;
}

.flags p {
  margin: 0;
}

.flags a {
  padding: 0 10px;
}

.flags a:hover {
  color: white !important;
  background-color: transparent !important;
}

.flags {
  font-size: 15px;
}

.logo_img {
  object-fit: cover;
}

.navbar-nav .nav-link {
  margin: 0 10px;
}

.navbar-nav .nav-link>a {
  text-decoration: none !important;
  color: #fff !important;
}

.navbar img {
  height: 60px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  background-color: #0C0032 !important;
  z-index: 1002;
}

#collasible-nav-dropdown {
  color: #fff;
}

.language #collasible-nav-dropdown {
  border-bottom: 2px solid white;
  font-size: 15px;
  padding: 5px;
}

.language .dropdown-toggle::after {
  display: none;
}

.top_section .dropdown-toggle::after {
  display: none;
}


button:focus:not(:focus-visible) {
  box-shadow: none;
}

.dropdown-menu[data-bs-popper] {
  background-color: #0C0032;
  min-width: 30px !important;
}

.dropdown-menu[data-bs-popper]:hover {
  color: white;
}

.dropdown-item a {
  color: white !important;
}

.dropdown-item {
  color: white !important;
}

.dropdown-item:hover {
  color: white !important;
  background-color: #0C0061 !important;
}

.last_item a {
  font-size: 15px !important;
  cursor: context-menu;
  position: relative;
}

.number {
  font-size: 12px;
  position: absolute;
  width: max-content;
  top: 30px;
}

@media only screen and (min-width: 992px) {

  .navbar-nav {
    align-items: center;
  }
}

@media only screen and (max-width: 760px) {
  .language img {
    height: 20px !important;
    width: 20px !important;
    object-fit: cover;
    margin-right: 5px;
  }

  .language p {
    margin: 0;
  }

  .language a {
    padding: 0 10px;
  }

  .language a:hover {
    color: white !important;
    background-color: transparent !important;
  }

  .language {
    font-size: 15px;
  }

  .navbar-nav .nav-link>a {
    margin: 0px;
  }

  .language #collasible-nav-dropdown {
    display: inline-block;
  }

  .language .dropdown-menu {
    width: max-content;
  }

  .language .dropdown-menu>.dropdown-item {
    display: inline;
  }

  .navbar {
    padding-bottom: 10px !important;
  }
}