.headerbg {
    width: 100%;
    height: 70vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
    .headerbg{
        height: 50vh;
        padding-top: 100px !important;
    }
}