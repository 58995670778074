.btn1{
    border:  2px solid #fff;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    width: max-content;
    height: 40px;
    padding: 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: .5s;

}
.btn1 a{
    color: #fff !important;
}
.btn1::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 19px;
    top: 0;
    left: 0;
    background-color: #fff;
    transform: scaleX(0);
    z-index: -1;
    transition:transform  .5s;


}
.btn1:hover {
    color: #0c0061;
    z-index: 9999;
    border:  2px solid #fff;
    transition:transform  .5s;


}
.btn1:hover a{
    color: #0c0061 !important;
    font-weight: bold;
}
.btn1:hover::before{
    transform: scaleX(1);
}

@media only screen and (max-width: 768px) {
    .btn1{
        font-size: 12px;
        padding: 0 7px;
        border: 1px solid white;
    }
}

