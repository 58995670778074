.iso__sertificats{
  font-size: 40px;
  color: white;
  font-weight: bold;
  /* margin-top: 20px; */
}

.sertificats img{
  height: auto;
  width: 200px;
  object-fit: contain;
}

.sertificats .card{
  background-color: transparent !important;
}

.statistics_about_us{
  background-color: white;
  padding: 100px 0;
}


.__react_modal_image__medium_img{
  width: auto !important;
  height: 700px !important;
  background-color: white !important;
  object-fit: cover;
  padding: 1rem;
} 