.li{
    font-size: 14px;
}

.text1{
    opacity: .5;
    cursor: pointer;
}
.text2{
    opacity: 1;
    cursor: pointer;
}
.parms{
    height: 400px;
}
h4{
    margin: 20px 0;
}

@media (max-width: 576px) {
    h4{
        font-size: 15px;
        font-weight: bolder;
    }
    .li{
        width: 80% !important;
        font-size: 12px;
        line-height: 20px;
        float: right !important;
    }
}