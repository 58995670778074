.firstSection{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
li{
    margin: 10px 0;
    padding: 0 !important;
}

.imgSec img{
    width: 200px;
    height: 250px;

}
.imgSec{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px !important; 
    margin-right: 100px;    
}

.swiper-wrapper div{
    margin-bottom: 15px;
}

.myh4{
    margin-bottom: 70px !important;
}
 .line{
    width: 100px;
    border: 1px solid #fff;
    position: absolute;
    top: 0;
    right: -10px;
}
 .line1{
    height: 100px;
    border: 1px solid #fff;
    position: absolute;
    top: -12px;
    right:0;
}
 .line2{
    height: 100px;
    border: 1px solid #fff;
    position: absolute;
    bottom: -12px;
    left: 0;
}
 .line3{
    width: 100px;
    border: 1px solid #fff;
    position: absolute;
    bottom: 0;
    left: -12px;
}
.bottomline{
    width: 90%;
    border: 1px solid #fff;
    border-radius: 20%;
    margin-top: 50px;
}
.swiper {
    width:70%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;
}
  
  .swiper-slide img {
    display: block;
    width: 200px;
    height: 200px;
    /* box-shadow: 0 0 5px 2.5px #fff; */
}
.swiper-pagination-bullet{
    background-color: #fff !important;
    opacity: .6 !important;
}
.swiper-pagination-bullet-active{
    background-color: blue !important;
    opacity: 1 !important;
}