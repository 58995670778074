.footer{
  /* background-image: url('../../../assets/bgimages/Group 2185.png'); */
  background-color: #0C0061;
  padding-top: 10px;
  height: auto;
}

.contact_text_footer{
  width: 300px;
  display: inline-block;
}

.img_logo{
  height: 80px;
}

.bottom_div p{
  margin-bottom: 0;
  font-size: 18px;
  font-weight: lighter;
}

.deskription_footer{
  font-size: 18px;
  font-weight: lighter;
}

@media (max-width: 576px) {
  .foot{
    background-size: cover !important;
  }
  
  .bottom_div p {
  padding: 0 15px;
  }

}